<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="text"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: 'Username', mode: 'text' }"
      >
        <dx-required-rule :message="loc.LOGIN.REQUIREDUSERNAMEMESSAGE" />
        <!--<dx-email-rule message="Email is invalid" />-->
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field='password'
        editor-type='dxTextBox'
        :editor-options="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }"
      >
        <dx-required-rule :message="loc.LOGIN.REQUIREDPASSWORDMESSAGE" />
        <dx-label :visible="false" />
      </dx-item>

      <!--
      <dx-item
        data-field="rememberMe"
        editor-type="dxCheckBox"
        :editor-options="{ text: 'Remember me', elementAttr: { class: 'form-text' } }"
      >
        <dx-label :visible="false" />
      </dx-item>
      -->

      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>

      <!--
      <dx-item>
        <template #default>
          <div class="link">
            <router-link to="/reset-password">Forgot password?</router-link>
          </div>
        </template>
      </dx-item>
      -->

      <!--
      <dx-button-item>
        <dx-button-options
          text="Create an account"
          width="100%"
          :on-click="onCreateAccountClick"
        />
      </dx-button-item>
      -->

      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">{{ loc.LOGIN.LOGINBUTTON }}</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  //DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';

import auth from "../auth";
import { loc } from "@/config/languages/en.js";

export default {
  data() {
    return {
      formData: {},
      loading: false,
      loc: loc,
    };
  },
  methods: {
    onCreateAccountClick() {
      this.$router.push("/create-account");
    },
    onSubmit: async function() {
      const { text, password } = this.formData;
      this.loading = true;

      const result = await auth.logIn(text, password);

      if (result?.isOk) {

        this.$router.push(this.$route.query.redirect || "/home");

      } else {

        this.loading = false;
        // messaggio bad credentials (tags: login, username, password, credenziali)
        // console.debug("bad credentials error: ", result.data);
        const badCredentialsMessage = result.data == "BadCredentials" ? loc.LOGIN.BADCREDENTIALSMESSAGE : loc.LOGIN.UNKNOWNERRORMESSAGE;
        notify(badCredentialsMessage, "error", 2000);
      }
    }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    //DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.dx-texteditor.dx-editor-filled.dx-state-hover::after {
  border: none !important;
}

.dx-texteditor.dx-editor-filled.dx-state-focused::before {
  border: none !important;
}

.dx-texteditor.dx-editor-filled .dx-texteditor-input {
  padding: 0 !important;
  line-height: normal !important;
}

.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 0 !important;
  padding-left: 19px !important;
}
//
//.dx-texteditor.dx-state-active::before, .dx-state-editor.dx-state-focused::before {
//  border: none !important;
//}


// messaggi errore (campi username e password mancanti)
.dx-overlay.dx-widget.dx-visibility-change-handler.dx-invalid-message {
  .dx-overlay-wrapper.dx-invalid-message.dx-invalid-message-auto{
    //visibility: visible !important;

    max-width: 395px;
    width: auto;
    height: auto;
    z-index: 1501;
    margin: 0px;
    left: 107px !important;
    top: -30px !important;
    transform: translate(0px, 0px) !important;
    transition: all 0s ease 0s;

    .dx-overlay-content.dx-invalid-message-content.dx-resizable{
        max-width: 395px;
        width: auto;
        height: auto;
        z-index: 1501;
        margin: 0px;
        left: 115px !important;
        top: -40px !important;
        transform: translate(0px, 0px) !important;
        transition: all 0s ease 0s;

    }
  }
}

// messaggi errore login (bad credentials)
.dx-overlay-content.dx-toast-error.dx-toast-content.dx-resizable {
  display: flex !important;
  align-items: center;

    width: 500px !important;
    //height: auto !important;
    //z-index: 9501 !important;
    //margin: 0px !important;
    left: 50vw !important;
    top: 50vh !important;
    transform: translate(-50%, 185px) !important;
    //transition: none 0s ease 0s !important;
    //visibility: visible !important;
    //opacity: 1 !important;

  .dx-toast-icon {

  }
  .dx-toast-message {

  }

}

.dx-card.content {

  border-radius: 24px !important;
  //background-color: rgba(211, 211, 211, 0.5) !important;
  background-color: hsla(120deg, 0%, 95%, 0.5) !important;
  font-family: Nunito, sans-serif;
  border: none;
  box-shadow: 0 1px 3px rgb(150 150 150 / 12%), 0 1px 2px rgb(150 150 150 / 24%);

  .header {
    .title {
      //font-size: 28px;
      //font-weight: 400;
      //line-height: 38px;
      //text-align: center;
      //color: #015560;
    }
  }

  .dx-editor-filled {
    background-color: transparent !important;
    border: none;
  }

  .dx-editor-filled:hover {
    border: none !important;
  }

  .dx-texteditor-container {
    margin-bottom: 18.5px !important;
    width: 396px;
    height: 40px;
  }

  .dx-texteditor-input[type="text"], .dx-texteditor-input[type="password"]{
    border-radius: 30px;
    border: 1px solid #E3B41A;
    background-color: white !important;
  }

  /*
  .dx-item.dx-box-item:nth-child(5),
  .dx-item.dx-box-item:nth-child(6)
  {
    display: none !important;
  }
   */

  .dx-button-mode-contained.dx-button-default {
    background-color: #000000 !important;
    color: white !important;
    border-radius: 30px;
    margin-top: 25px;
    text-transform: capitalize !important;

    .dx-button-text {
      text-transform: none !important;

    }

  }

}

.login-form {

  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.dx-invalid-message>.dx-overlay-content {
   background-color: #F44335!important;
   color: white !important;
   padding: 6px 6px 6px !important;
}
</style>
